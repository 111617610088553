import cn from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import SubmitButtonSVG from '@/assets/images/airdrop/submit_button.inline.svg';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';

import AuthCode from './AuthCode';

export function InviteCodeVerify() {
  const { userInfo, setUserInfo } = useAppStore();
  const [referralCode, setReferralCode] = useState('');
  const handleOnChange = (code: string) => {
    setReferralCode(code);
  };
  return (
    <div className="flex flex-col items-center gap-y-8">
      <div className="mx-auto max-w-[300px] sm:max-w-[492px]">
        <AuthCode onChange={handleOnChange} />
      </div>
      <button
        className={cn(
          'duration-300 hover:opacity-75',
          !userInfo && 'cursor-not-allowed opacity-75'
        )}
        onClick={() => {
          if (userInfo && referralCode.length === 6) {
            api.userController
              .bindReferralUsingPOST({ referralCode })
              .then(() => {
                setUserInfo({ ...userInfo, referralCode });
              })
              .catch(() => {
                toast.error('Failed to bind invite code');
              });
          }
        }}
      >
        <SubmitButtonSVG />
      </button>
    </div>
  );
}
