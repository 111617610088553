// @ts-ignore
/* eslint-disable */
import request from '../request';

/** bind discord POST /v1/user/discord/bind */
export async function bindDiscordUsingPOST(
  body: API.DiscordRequest,
  options?: { [key: string]: any },
) {
  return request<any>('/v1/user/discord/bind', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** check if create inscription POST /v1/user/inscription/check */
export async function checkCreateInscriptionUsingPOST(options?: { [key: string]: any }) {
  return request<any>('/v1/user/inscription/check', {
    method: 'POST',
    ...(options || {}),
  });
}

/** get my user profile GET /v1/user/me */
export async function meUsingGET(options?: { [key: string]: any }) {
  return request<API.User>('/v1/user/me', {
    method: 'GET',
    ...(options || {}),
  });
}

/** metaMask login POST /v1/user/metaMask/login */
export async function metaMaskLoginUsingPOST(
  body: API.MetaMaskRequest,
  options?: { [key: string]: any },
) {
  return request<API.TokenPairResponse>('/v1/user/metaMask/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** update user profile POST /v1/user/profile */
export async function profileUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.profileUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<any>('/v1/user/profile', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** bind referral code POST /v1/user/referralCode/bind */
export async function bindReferralUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.bindReferralUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<any>('/v1/user/referralCode/bind', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** check if register completed POST /v1/user/register/check */
export async function checkRegisterCompletedUsingPOST(options?: { [key: string]: any }) {
  return request<any>('/v1/user/register/check', {
    method: 'POST',
    ...(options || {}),
  });
}

/** bind twitter POST /v1/user/twitter/bind */
export async function bindTwitterUsingPOST(
  body: API.TwitterRequest,
  options?: { [key: string]: any },
) {
  return request<any>('/v1/user/twitter/bind', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}


