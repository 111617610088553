import { useCallback } from 'react';

import api from '@/services/api';
import { useAppStore } from '@/store/appStore';

export function useUpdateUserInfo() {
  const { setUserInfo } = useAppStore();

  const updateUserInfo = useCallback(() => {
    api.userController
      .meUsingGET()
      .then(setUserInfo)
      .catch((e) => {
        console.error(e);
        setUserInfo();
      });
  }, [setUserInfo]);

  const getUserInfoAsync = useCallback(
    () => api.userController.meUsingGET(),
    []
  );

  return { updateUserInfo, getUserInfoAsync };
}
