import { useLocation } from '@reach/router';
import cn from 'classnames';
import { Link } from 'gatsby';
import React, { useMemo } from 'react';

import AccountSVG from '@/assets/images/header/account.inline.svg';
import AirdropSVG from '@/assets/images/header/airdrop.inline.svg';
import CollectionsSVG from '@/assets/images/header/collections.inline.svg';
import DocSVG from '@/assets/images/header/doc.inline.svg';
import LeaderBoardSVG from '@/assets/images/header/leaderboard.inline.svg';
import MarketSVG from '@/assets/images/header/market.inline.svg';
import TokenSVG from '@/assets/images/header/token.inline.svg';
import { useAppStore } from '@/store/appStore';

export function Nav() {
  const { pathname } = useLocation();
  const { userInfo } = useAppStore();

  const navs = useMemo(
    () => [
      {
        subs: [
          {
            title: userInfo?.registeredTime ? 'Invite' : 'Airdrop',
            link: userInfo?.registeredTime ? '/invite' : '/airdrop',
            icon: <AirdropSVG className="shrink-0" />
          },
          {
            title: 'Leaderboard',
            link: '/leaderboard',
            icon: <LeaderBoardSVG className="shrink-0" />
          },
          {
            title: 'Account',
            link: '/account',
            icon: <AccountSVG className="shrink-0" />
          }
        ]
      },
      {
        title: 'Collections',
        link: '/collections',
        icon: <CollectionsSVG />
      },
      {
        title: 'Token',
        link: '/token',
        icon: <TokenSVG />
      },
      {
        title: 'Market',
        link: '/market',
        icon: <MarketSVG />
      },
      {
        title: 'Doc',
        link: '/doc',
        icon: <DocSVG />
      }
    ],
    [userInfo?.registeredTime]
  );
  const activeMenu = useMemo(() => {
    if (
      ['/airdrop', '/account', '/leaderboard', '/invite'].includes(pathname)
    ) {
      return navs[0]?.subs?.find((item) => item.link === pathname);
    }
  }, [navs, pathname]);
  return (
    <nav className="hidden flex-1 lg:block">
      <ul className="flex items-center justify-end gap-x-2 pl-4">
        {navs.map((nav, index) => {
          const hasSub = !!nav.subs;
          if (hasSub) {
            return (
              <li
                key={index}
                className="group relative cursor-pointer rounded-lg bg-[#C88E3733] px-4 py-2"
              >
                <div className="flex items-center gap-x-2">
                  {activeMenu?.icon}
                  <span className="text-lg font-semibold !leading-[1]">
                    {activeMenu?.title}
                  </span>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.52413 10.3678L12.3817 15.2254L17.2393 10.3678C17.715 9.87956 18.5163 9.87956 19.0045 10.3678C19.4803 10.8436 19.4803 11.6448 19.0045 12.1331L13.2581 17.8795C12.7698 18.3553 11.9811 18.3678 11.4928 17.8795C11.4803 17.867 11.4803 17.867 11.4803 17.867L5.73384 12.1205C5.24558 11.6323 5.23306 10.8436 5.72132 10.3553C5.72132 10.3428 5.72132 10.3428 5.72132 10.3428C6.20958 9.86704 7.01083 9.85452 7.49909 10.3428L7.52413 10.3678Z"
                      fill="#342917"
                    />
                  </svg>
                </div>
                <ul
                  className={cn(
                    'absolute inset-x-0 top-12 flex h-0 flex-col items-center justify-center gap-y-1 overflow-hidden rounded-lg bg-[#EAECDC] px-2 shadow-[0px_8px_5px_0px_rgba(0,0,0,0.08),0px_20px_13px_0px_rgba(0,0,0,0.03)] transition-[height] duration-300 group-hover:h-[164px]'
                  )}
                >
                  {nav.subs?.map((sub) => (
                    <Link to={sub.link} key={sub.link} className="w-full">
                      <li className="flex w-full items-center justify-start gap-x-1 px-1 py-2">
                        {sub.icon}
                        <span className="text-lg font-semibold !leading-[1]">
                          {sub.title}
                        </span>
                      </li>
                    </Link>
                  ))}
                </ul>
              </li>
            );
          }
          return (
            <li
              key={index}
              className="flex cursor-not-allowed items-center gap-x-2 px-4"
            >
              {nav.icon}
              <span className="text-lg font-semibold !leading-[1]">
                {nav.title}
              </span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
