import cn from 'classnames';
import React, { MouseEvent, ReactNode, useMemo } from 'react';

import LoadingSvg from '@/assets/images/common/loading.inline.svg';

export enum EButtonType {
  PRIMARY = 'PRIMARY',
  GHOST = 'GHOST'
}
interface IButtonProps {
  className?: string;
  children: ReactNode;
  type?: EButtonType;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonType?: 'submit' | 'reset' | 'button';
}
export function Button(props: IButtonProps) {
  const {
    className,
    children,
    type = EButtonType.PRIMARY,
    loading = false,
    onClick,
    buttonType
  } = props;
  const cls = useMemo(() => {
    const resultArray = [
      'duration-300 rounded-lg flex items-center text-sm sm:text-[20px] justify-center font-Courier text-[#FAFAFA]'
    ];
    switch (type) {
      case EButtonType.PRIMARY:
        resultArray.push('bg-[#342917] hover:opacity-75');
        break;
      case EButtonType.GHOST:
        resultArray.push('bg-[#474a62] hover:opacity-75');
        break;
      default:
        break;
    }
    return resultArray.join(' ');
  }, [type]);

  return (
    <button
      type={buttonType}
      onClick={(e) => {
        if (!loading && onClick) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick(e as any);
        }
      }}
      className={cn(cls, className)}
    >
      {loading ? <LoadingSvg className="animate-spin" width={24} /> : children}
    </button>
  );
}
