/* eslint-disable @typescript-eslint/prefer-for-of */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import { useUpdate } from 'react-use';

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'] as const;

export interface AuthCodeProps {
  allowedCharacters?: typeof allowedCharactersValues[number];
  ariaLabel?: string;
  autoFocus?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  inputClassName?: string;
  isPassword?: boolean;
  length?: number;
  placeholder?: string;
  onChange: (res: string) => void;
}

type InputMode = 'text' | 'numeric';

type InputType = 'text' | 'tel' | 'password';

interface InputProps {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
}

export interface AuthCodeRef {
  focus: () => void;
  clear: () => void;
}

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}'
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}'
  },

  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9'
  }
};

const AuthCode = forwardRef<AuthCodeRef, AuthCodeProps>(
  (
    {
      allowedCharacters = 'alphanumeric',
      ariaLabel,
      autoFocus = true,
      disabled,
      isPassword = false,
      length = 6,
      placeholder,
      onChange
    },
    ref
  ) => {
    if (isNaN(length) || length < 1) {
      throw new Error('Length should be a number and greater than 0');
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
      throw new Error(
        'Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric'
      );
    }
    const update = useUpdate();

    const inputsRef = useRef<HTMLInputElement[]>([]);
    const inputProps = propsMap[allowedCharacters];

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join('');
      onChange && onChange(res);
      update();
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = '';
          }
          inputsRef.current[0].focus();
        }
        sendResult();
      }
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
    }, [autoFocus]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling }
      } = e;
      if (value.length > 1) {
        e.target.value = value.charAt(0);
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        if (RegExp(inputProps.pattern).exec(value)) {
          if (nextElementSibling !== null) {
            (nextElementSibling as HTMLInputElement).focus();
          }
        } else {
          e.target.value = '';
        }
      }
      sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      const target = e.target as HTMLInputElement;
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement;
            t.value = '';
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = '';
        }
        sendResult();
      }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('Text');

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput].value;
        if (RegExp(inputProps.pattern).exec(pastedCharacter)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter;
            if (inputsRef.current[currentInput].nextElementSibling !== null) {
              (
                inputsRef.current[currentInput]
                  .nextElementSibling as HTMLInputElement
              ).focus();
              currentInput++;
            }
          }
        }
      }
      sendResult();

      e.preventDefault();
    };

    const inputs = [];
    for (let i = 0; i < length; i++) {
      inputs.push(
        <input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          {...inputProps}
          type={isPassword ? 'password' : inputProps.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el;
          }}
          maxLength={1}
          className="outline:none aspect-square w-[13.6%] overflow-hidden rounded-lg border-[2px] border-solid border-[#342917] bg-[rgba(0,0,0,0)] text-center text-xl focus-visible:outline-none sm:rounded-[14px] sm:border-[3px] sm:text-2xl"
          autoComplete={i === 0 ? 'one-time-code' : 'off'}
          aria-label={
            ariaLabel
              ? `${ariaLabel}. Character ${i + 1}.`
              : `Character ${i + 1}.`
          }
          disabled={disabled}
          placeholder={placeholder}
        />
      );
    }

    return (
      <div className="relative">
        <div className="mx-auto flex max-w-[300px] flex-row items-center justify-between sm:max-w-[407px]">
          {inputs}
        </div>
        {/* <div className="absolute inset-x-0 bottom-2 flex flex-row items-center justify-between sm:bottom-3">
          {inputsRef.current.map((item, index) => (
            <div key={index} className="w-[13.6%]">
              <div
                className={cn(
                  'mx-3 h-[2px] w-[calc(100%_-_24px)] bg-[#342917] sm:h-[3px] sm:px-5',
                  item.value && 'hidden'
                )}
              ></div>
            </div>
          ))}
        </div> */}
      </div>
    );
  }
);

export default AuthCode;
