/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */

import jwtDecode from 'jwt-decode';

export enum ETokenType {
  EMAIL_PASSWORD = 'EmailPassword',
  GOOGLE = 'Google',
  EMAIL_CODE = 'EmailCode',
  GITHUB_APP = 'GithubApp',
  USERNAME_PASSWORD = 'UsernamePassword'
}
interface ITokenPayload {
  aud: string;
  nbf: number;
  appId: string;
  iss: string;
  /**
   * Token expiration time in seconds
   */
  exp: number;
  strategy: ETokenType;
  iat: number;
  userId: string;
  authorities: string[];
}

export class TokenUtils {
  static decodeToken(token: string): ITokenPayload {
    try {
      return jwtDecode<ITokenPayload>(token);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return {} as any;
    }
  }

  static decodeTokenType(token: string): ETokenType | undefined {
    if (!token) return undefined;
    return this.decodeToken(token).strategy;
  }

  static checkTokenExpires(token: string, time?: number): boolean {
    if (!time) {
      time = Date.now();
    }
    try {
      const { exp } = this.decodeToken(token);
      if (exp === undefined) return true;
      return exp * 1000 < time;
    } catch (e) {
      return true;
    }
  }
}
