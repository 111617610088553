import { BASE_URL } from 'gatsby-env-variables';
import { extend } from 'umi-request';

import { EStorageKeys } from '@/utils/constant';
const request = extend({
  prefix: BASE_URL,
  timeout: 30 * 1000 // 30s
});

request.interceptors.request.use((url, options) => {
  const accessToken = localStorage.getItem(EStorageKeys.ACCESS_TOKEN);
  if (accessToken) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...options.headers
    };
  }

  return {
    url,
    options
  };
});

// 响应拦截器
request.interceptors.response.use((response) => {
  if (
    response.status === 200 &&
    response.headers.get('Content-Type') === 'application/json'
  ) {
    return response.json().then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data;
    });
  }
  return response;
});

export default request;
