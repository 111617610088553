import { navigate } from 'gatsby';
import React from 'react';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';
import { v4 as uuid } from 'uuid';
import {
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useSwitchNetwork
} from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { EStorageKeys } from '@/utils/constant';
import { TokenUtils } from '@/utils/tokenUtils';

import { Button, EButtonType } from './Button';
import { walletChain } from './RootElement';

export const nonce = `Evm ink sign message ${uuid()}`;
export function Account() {
  const { address, isConnected } = useAccount();
  const { userInfo, setUserInfo } = useAppStore();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { disconnectAsync, disconnect } = useDisconnect();
  const { connect, connectAsync } = useConnect({
    connector: new InjectedConnector(),
    onSuccess(data) {
      if (data.chain.id !== walletChain.id && switchNetworkAsync) {
        // 执行切换网络
        void switchNetworkAsync(walletChain.id).catch(() => {
          disconnect();
          setUserInfo();
          toast.error('Please switch to sepolia network');
        });
      }
    },
    onError() {
      localStorage.removeItem(EStorageKeys.ACCESS_TOKEN);
      setUserInfo();
    }
  });
  const { signMessageAsync } = useSignMessage({ message: nonce });
  useMount(() => {
    const accessToken = localStorage.getItem(EStorageKeys.ACCESS_TOKEN);
    if (accessToken && !TokenUtils.checkTokenExpires(accessToken)) {
      // 更新userInfo
      api.userController
        .meUsingGET()
        .then((user) => {
          setUserInfo(user);
          if (!isConnected) {
            connect();
          }
        })
        .catch((e) => {
          console.error(e);
          setUserInfo(undefined);
        });
    }
  });
  if (userInfo)
    return (
      <div className="flex items-center gap-x-1">
        <span>
          {address?.slice(0, 2)}...{address?.slice(-2)}
        </span>
        <Button
          type={EButtonType.PRIMARY}
          className="h-[36px] w-[120px] !text-base"
          onClick={() => {
            void disconnectAsync().then(() => {
              setUserInfo();
              localStorage.removeItem(EStorageKeys.ACCESS_TOKEN);
              setUserInfo();
              void navigate('/');
            });
          }}
        >
          Disconnect
        </Button>
      </div>
    );
  return (
    <button
      onClick={async () => {
        try {
          const { account: address } = await connectAsync();
          const signature = await signMessageAsync();
          const tokenRes = await api.userController.metaMaskLoginUsingPOST({
            address,
            signature,
            nonce
          });
          if (tokenRes.accessToken) {
            localStorage.setItem(
              EStorageKeys.ACCESS_TOKEN,
              tokenRes.accessToken
            );
            api.userController
              .meUsingGET()
              .then((user) => {
                setUserInfo(user);
              })
              .catch((e) => {
                console.error(e);
                setUserInfo(undefined);
              });
          } else {
            void disconnectAsync().catch(console.error);
          }
        } catch (e) {
          console.error(e);
          void disconnectAsync().catch(console.error);
        }
      }}
      className="flex items-center gap-x-2 rounded-lg border-[2px] border-solid border-[#342917] bg-[#342917] px-3 py-[2px]"
    >
      <span className="mt-1 font-semibold text-[#FAFAFA]">Connect</span>
    </button>
  );
}
