import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface AppState {
  userInfo?: API.User;
  setUserInfo: (user?: API.User) => void;
}

// Redux devtool is integrated by default to facilitate observation of data flow, immer makes data immutable, and persist makes data persistent.
export const useAppStore = create<AppState>()(
  persist(
    devtools(
      immer((set, get) => ({
        // Update state through set, and get the latest value through get()
        setUserInfo: (user) =>
          set(
            (state) => {
              state.userInfo = user;
            },
            // Using immer can only be false here
            false,
            // The action name displayed in redux devtool
            'user/setUserInfo'
          )
      }))
    ),
    {
      name: 'edu-app-store',
      // saved items
      partialize: (state) => ({
        userInfo: state.userInfo
      })
    }
  )
);
