import 'react-toastify/dist/ReactToastify.css';
import '@/styles/global.css';

import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { createPublicClient, http } from 'viem';
import { createConfig, mainnet, sepolia, WagmiConfig } from 'wagmi';

import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}
const queryClient = new QueryClient();

export const walletChain = sepolia;

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: walletChain,
    transport: http()
  })
});

export function RootElement(props: RootElementProps) {
  const { children } = props;

  return (
    <WagmiConfig config={config}>
      <NiceModal.Provider>
        <I18nextProvider>
          <QueryClientProvider client={queryClient}>
            <ToastContainer
              autoClose={5000}
              newestOnTop={false}
              closeOnClick={true}
              rtl={false}
              draggable={false}
              pauseOnHover={false}
            />
            {children}
          </QueryClientProvider>
        </I18nextProvider>
      </NiceModal.Provider>
    </WagmiConfig>
  );
}
