import React, { ReactNode } from 'react';

interface ITaskLayoutProps {
  children: ReactNode;
  sequence: number;
  taskName: ReactNode;
}
export function TaskLayout(props: ITaskLayoutProps) {
  const { children, sequence, taskName } = props;
  return (
    <div className="mb-[26px] flex w-full max-w-[648px] items-center bg-[linear-gradient(90deg,rgba(227,203,154,0.30)_0.02%,rgba(222,199,156,0.00)_131.36%)] py-[11px] pl-[10px] shadow-[0px_-1px_0px_0px_#B0A68D_inset]">
      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded bg-[#C88E3733] pt-[3px] font-Courier font-semibold text-[#342917] sm:h-11 sm:w-11 sm:rounded-lg sm:text-lg">
        {sequence}
      </div>
      <div className="flex flex-1 items-center justify-between overflow-hidden">
        <span className="flex-1 font-Inter text-sm font-medium leading-[1.45] text-[#404040] sm:text-[22px]">
          {taskName}
        </span>
        {children}
      </div>
    </div>
  );
}
