import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { useWindowScroll } from 'react-use';

import LogoSVG from '@/assets/images/common/logo.inline.svg';
import CreateSVG from '@/assets/images/header/create.inline.svg';

import { Account } from './Account';
import { Nav } from './Nav';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const { y } = useWindowScroll();

  return (
    <header
      className={cn(
        'fixed inset-x-0 z-10 h-[88px] font-Courier',
        className,
        y > 0 && 'bg-[#e2d6b5]'
      )}
    >
      <div className="page-layout flex h-full items-center justify-between">
        <Link to="/">
          <LogoSVG className="shrink-0" />
        </Link>
        <Nav />
        <div className="ml-2 flex items-center gap-x-2">
          <button className="flex cursor-not-allowed items-center gap-x-2 rounded-lg border-[2px] border-solid border-[#342917] px-3 py-[2px]">
            <CreateSVG />
            <span className="mt-1 font-semibold">Create</span>
          </button>
          <Account />
        </div>
      </div>
    </header>
  );
}
