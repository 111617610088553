import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { Button, EButtonType } from './Button';

interface IModalProps {
  title?: ReactNode;
  desc?: ReactNode;
  okBtnText?: string;
  cancelBtnText?: string;
}
export enum EModalResolveType {
  CLOSE_MODAL = 'close modal',
  REJECT = 'reject',
  RESOLVE = 'resolve'
}
export const Modal = NiceModal.create((props: IModalProps) => {
  const { title, desc, okBtnText = 'Confirm', cancelBtnText } = props;
  const modal = useModal();
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={modal.visible}
      onRequestClose={() => {
        modal.resolve(EModalResolveType.CLOSE_MODAL);
        return modal.hide();
      }}
      onAfterClose={modal.remove}
      className="fixed top-1/2 left-1/2 flex w-[calc(100vw_-_32px)] max-w-[642px] -translate-x-[50%] -translate-y-[50%] flex-col gap-y-10 rounded-lg bg-[#ebedde] px-4 pt-[39px] pb-[35px] text-[#000] sm:rounded-[30px] sm:px-[92px]"
    >
      <h2 className="text-center font-Courier text-xl font-semibold leading-[1] tracking-[-0.72px] sm:text-[36px]">
        {title}
      </h2>
      <p className="text-center font-Inter text-sm sm:text-lg">{desc}</p>
      <div className="flex flex-row-reverse flex-wrap items-center justify-center gap-x-4 gap-y-3">
        {okBtnText && (
          <Button
            type={EButtonType.PRIMARY}
            onClick={() => {
              modal.resolve(EModalResolveType.RESOLVE);
              void modal.hide();
            }}
            className="h-[44px] w-full sm:w-[171px]"
          >
            {okBtnText}
          </Button>
        )}
        {cancelBtnText && (
          <Button
            type={EButtonType.GHOST}
            onClick={() => {
              modal.resolve(EModalResolveType.REJECT);
              void modal.hide();
            }}
            className="h-[44px] w-full sm:w-[171px]"
          >
            {cancelBtnText}
          </Button>
        )}
      </div>
    </ReactModal>
  );
});

export const showModal = (params: IModalProps): Promise<EModalResolveType> => {
  return NiceModal.show(Modal, params);
};
