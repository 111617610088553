// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as basicErrorController from './basicErrorController';
import * as rankingController from './rankingController';
import * as referralController from './referralController';
import * as userController from './userController';
export default {
  basicErrorController,
  rankingController,
  referralController,
  userController,
};
