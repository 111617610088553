// @ts-ignore
/* eslint-disable */
import request from '../request';

/** query user by points GET /v1/ranking/points */
export async function queryUserByPointsUsingGET(options?: { [key: string]: any }) {
  return request<API.UserResponse[]>('/v1/ranking/points', {
    method: 'GET',
    ...(options || {}),
  });
}

/** query user by registeredTime GET /v1/ranking/recently */
export async function queryUserByRegisteredTimeUsingGET(options?: { [key: string]: any }) {
  return request<API.UserResponse[]>('/v1/ranking/recently', {
    method: 'GET',
    ...(options || {}),
  });
}
