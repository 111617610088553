// @ts-ignore
/* eslint-disable */
import request from '../request';

/** get my referral code GET /v1/referral/code/me */
export async function myReferralCodeUsingGET(options?: { [key: string]: any }) {
  return request<API.ReferralCode[]>('/v1/referral/code/me', {
    method: 'GET',
    ...(options || {}),
  });
}
